import { changePassword } from "api/auth";
import CustomToast from "components/toast/CustomToast";
import routes from "constants/routes";
import { useFormik } from "formik";
import * as React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useMutation } from "react-query";
import * as authService from "services/auth";
import history from "utils/history";
import { ChangePasswordValidation } from "./ProfileValidation";

const ChangePassword: React.FC = () => {
  const [errMsg, setErrMsg] = React.useState<string>("");
  const [successData, setSuccessData] = React.useState<any>();

  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      confirm_password: "",
    },
    validate: ChangePasswordValidation,
    onSubmit: (data: any) => {
      updatePassword.mutate(data);
    },
  });

  const updatePassword = useMutation((data: any) => changePassword(data), {
    onSuccess: (res: any) => {
      setErrMsg("");
      formik.resetForm();
      setSuccessData({
        title: "Please login with new password.",
        status: "success",
      });
      authService.logout();
      history.push(routes.auth.login);
    },
    onError: (error: any) => {
      if (error?.response?.data?.error?.details)
        setErrMsg(error?.response?.data?.error?.details[0].message);
    },
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
        <Form.Group className="mb-3">
          <Form.Label>Current Password</Form.Label>
          <Col sm="10">
            <Form.Control
              type="password"
              placeholder="Current Password"
              name="old_password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                formik.touched.old_password && formik.errors.old_password
                  ? "form-control is-invalid"
                  : "form-control"
              }
              required
              value={formik.values.old_password}
            />
            {formik.touched.old_password && formik.errors.old_password ? (
              <Form.Control.Feedback type="invalid">
                {formik.errors.old_password}
              </Form.Control.Feedback>
            ) : null}
          </Col>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>New Password</Form.Label>
          <Col sm="10">
            <Form.Control
              type="password"
              placeholder="New Password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                formik.touched.password && formik.errors.password
                  ? "form-control is-invalid"
                  : "form-control"
              }
              required
              value={formik.values.password}
            />
            <Form.Text className="text-muted">
              Minimum 8 chars, at least one uppercase, one lowercase, one number
              and one special char.
            </Form.Text>
            {formik.touched.password && formik.errors.password ? (
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
            ) : null}
          </Col>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Confirm Password</Form.Label>
          <Col sm="10">
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              name="confirm_password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                formik.touched.confirm_password &&
                formik.errors.confirm_password
                  ? "form-control is-invalid"
                  : "form-control"
              }
              required
              value={formik.values.confirm_password}
            />
            {formik.touched.confirm_password &&
            formik.errors.confirm_password ? (
              <Form.Control.Feedback type="invalid">
                {formik.errors.confirm_password}
              </Form.Control.Feedback>
            ) : null}
          </Col>
        </Form.Group>

        <Button variant="primary" type="submit">
          Change Now
        </Button>
      </Form>
      {successData && <CustomToast toastData={successData} />}
    </>
  );
};

export default ChangePassword;
