import UserResource from 'api/user';
import { ADMIN_ROLES, SUPER_ADMIN_ROLES } from 'constants/common';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Badge, Button, Form, Modal } from 'react-bootstrap';
import { BiEdit, BiShow } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import HideControl from 'services/HideControl';
import { truncateString } from 'utils';
import history from 'utils/history';
import TrackifyToast from 'utils/toast';

interface Props {
  teamData: any;
}

const TeamMemberListItem: React.FC<Props> = (props) => {
  const { teamData } = props;
  const memberAPI = new UserResource();
  const [show, setShow] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');
  const queryClient = useQueryClient();

  const getGroups = () => {
    if (teamData?.groups?.length > 0) {
      const groups = teamData?.groups?.map((res: any) => {
        return res?.group_detail?.name;
      });
      return (
        <Badge bg="info" title={groups.join(', ')}>
          {truncateString(groups.join(', '))}
        </Badge>
      );
    }
    return '-';
  };

  const { userDetail } = useSelector(
    (state: any) => ({
      userDetail: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const EditUser = () => {
    if (SUPER_ADMIN_ROLES.includes(userDetail.role_id)) {
      if (
        teamData &&
        SUPER_ADMIN_ROLES.includes(teamData?.role_id) &&
        userDetail?.id !== teamData?.id
      ) {
        TrackifyToast.error('You do not have access to edit this user.');
      } else {
        history.push(
          routes.teams.members.edit.replace(':id', teamData?.id.toString())
        );
      }
      return;
    }
    if (
      teamData &&
      ADMIN_ROLES.includes(teamData?.role_id) &&
      userDetail?.id !== teamData?.id
    ) {
      TrackifyToast.error('You do not have access to edit this user');
    } else {
      history.push(
        routes.teams.members.edit.replace(':id', teamData?.id.toString())
      );
    }
  };

  const handleClose = () => {
    setDeleteErrorMsg('');
    setShow(false);
  };
  const handleShow = () => {
    if (SUPER_ADMIN_ROLES.includes(userDetail.role_id)) {
      if (
        teamData &&
        SUPER_ADMIN_ROLES.includes(teamData?.role_id) &&
        userDetail?.id !== teamData?.id
      ) {
        TrackifyToast.error('You do not have access to edit this user');
      } else {
        setShow(true);
      }
      return;
    }
    if (
      teamData &&
      ADMIN_ROLES.includes(teamData?.role_id) &&
      userDetail?.id !== teamData?.id
    ) {
      TrackifyToast.error('You do not have access to edit this user');
    } else {
      setShow(true);
    }
  };

  const deleteTeamGroup = useMutation(
    (data: any) => memberAPI.update(teamData?.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('teamMemberList');
        handleClose();
        TrackifyToast.success('Member updated');
      },
      onError: (error: any) => {
        setDeleteErrorMsg(error?.response?.data?.error?.message);
      },
    }
  );

  const onDeleteConfirm = () => {
    const status = teamData?.status === 'active' ? 'inactive' : 'active';
    deleteTeamGroup.mutate({ status: status });
  };

  return (
    <>
      <tr key={teamData?.id}>
        <td
          style={
            teamData?.status === 'inactive'
              ? { textDecoration: 'line-through' }
              : { textDecoration: 'none' }
          }>
          {teamData?.name}
        </td>
        <td title={teamData?.email}>{truncateString(teamData?.email)}</td>
        <td>{<Badge bg="info">{teamData?.role?.name}</Badge> || '-'}</td>
        <td>{getGroups()}</td>
        <HideControl
          renderNoAccess={(data: any) => {
            return data ? data : '';
          }}>
          <td>
            <Form>
              <Form.Check
                type="switch"
                id="custom-switch"
                onChange={handleShow}
                checked={teamData?.status === 'active' ? true : false}
              />
            </Form>
          </td>
          <td className="action-buttons">
            <button className="btn btn-link" onClick={EditUser}>
              <BiEdit />
            </button>
            <button
              className="btn btn-link"
              onClick={() =>
                history.push(
                  routes.teams.members.view.replace(
                    ':id',
                    teamData.id.toString()
                  )
                )
              }>
              <BiShow />
            </button>
          </td>
        </HideControl>
      </tr>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {teamData?.status === 'active' ? 'Inactive' : 'Active'} Member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteErrorMsg && (
            <p className="alert-danger p-2">{deleteErrorMsg}</p>
          )}
          Are you sure you want to{' '}
          {teamData?.status === 'active' ? 'Inactive' : 'Active'} it ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onDeleteConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TeamMemberListItem;
