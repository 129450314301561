import UserResource from 'api/user';
import TeamMemberForm from 'components/teams/TeamMemberForm';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import history from 'utils/history';
import TrackifyToast from 'utils/toast';

const AddTeamMember: React.FC = () => {
  let teamMemberAPI = new UserResource();
  const [errMsg, setErrMsg] = useState<string>('');
  const methods = useForm<any>();

  const addTeamMember = useMutation((data: any) => teamMemberAPI.store(data), {
    onSuccess: (res: any) => {
      TrackifyToast.success('Member has been added');
      history.push(routes.teams.list);
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.error?.details
        ? error?.response?.data?.error?.details[0]?.message
        : error?.response?.data?.error?.message;

      setErrMsg(errorMessage);
    },
  });

  const onSubmit = (data: any) => {
    data.status = 'invited';
    addTeamMember.mutate(data);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.teams.list}>Team</Breadcrumb.Item>
        <Breadcrumb.Item href={`${routes.teams.list}?name=members`}>
          Team List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add Member</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Create Member</h2>
      <div className="col-md-8 mt-5">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
            <TeamMemberForm />

            <Button
              variant="primary"
              type="submit"
              style={{ float: 'right' }}
              disabled={methods.formState.isSubmitting}>
              Add
            </Button>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default AddTeamMember;
