import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { ColorPicker, useColor } from 'react-color-palette';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  projectData?: any;
  clientData?: any;
}

const ProjectForm: React.FC<Props> = (props) => {
  const { projectData, clientData } = props;

  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<any>();

  const [color, setColor] = useColor(
    'hex',
    projectData ? projectData.color_code : ''
  );

  const handleChange = (e: any) => {
    setColor(e);
    setValue('color_code', e.hex);
  };

  // color picker
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const handleColorClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleColorClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <>
      <form>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="formPlaintextProjectName">
          <Form.Label column sm="2">
            Name
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              className={errors.name ? 'is-invalid' : ''}
              defaultValue={projectData?.name}
              placeholder="Project Name "
              maxLength={24}
              {...register('name', {
                required:
                  'Please Enter Name. Must be between 3 to 24 characters',
                minLength: {
                  value: 3,
                  message: 'Must be atleast 3 characters',
                },
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.name && errors?.name?.message}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextClient">
          <Form.Label column sm="2">
            Client
          </Form.Label>
          <Col sm="10">
            <Controller
              control={control}
              name="client_id"
              render={({ field }) => (
                <select
                  className="form-control form-select"
                  placeholder="Select"
                  id="client_id"
                  {...field}
                  defaultValue={projectData?.client_id}>
                  <option value="">Select</option>
                  {clientData?.map((client: any) => {
                    return (
                      <option key={client.id} value={client.id}>
                        {client.name}
                      </option>
                    );
                  })}
                </select>
              )}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPrivacy">
          <Form.Label column sm="2">
            Privacy
          </Form.Label>
          <Col sm="10">
            <Controller
              control={control}
              name="privacy"
              defaultValue={projectData?.privacy || 'Private'}
              render={({ field }) => (
                <select
                  className="form-control form-select"
                  placeholder="Select"
                  id="privacy"
                  {...field}>
                  <option value="">Select</option>
                  <option value="Public">Public</option>
                  <option value="Private">Private</option>
                </select>
              )}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPrivacy">
          <Form.Label column sm="2">
            Is Billable
          </Form.Label>
          <Col sm="10">
            <Controller
              control={control}
              name="is_billable"
              render={({ field }) => (
                <select
                  className="form-control form-select"
                  placeholder="Select"
                  id="is_billable"
                  {...field}
                  defaultValue={projectData?.is_billable}>
                  <option value="">Select</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              )}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextStatus">
          <Form.Label column sm="2">
            Status
          </Form.Label>
          <Col sm="10">
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <select
                  className="form-control form-select"
                  placeholder="Select"
                  id="status"
                  {...field}
                  defaultValue={projectData?.status}>
                  <option value="">Select</option>
                  <option value="active">Active</option>
                  <option value="archived">Archived</option>
                  <option value="inactive">Inactive</option>
                </select>
              )}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextColor">
          <Form.Label column sm="2">
            Color
          </Form.Label>
          <Col sm="10" md="6">
            <Form.Control
              type="hidden"
              defaultValue={color.hex}
              value={color.hex}
              placeholder="Color Code"
              {...register('color_code', {
                required: 'Please enter colorCode',
              })}
            />
            <span
              onClick={() => handleColorClick()}
              style={{
                display: 'inline-block',
                backgroundColor: color.hex,
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                marginTop: '2%',
              }}></span>
            {displayColorPicker ? (
              <div style={{ position: 'absolute', zIndex: '2' }}>
                <div
                  style={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                  }}
                  onClick={() => handleColorClose()}
                />
                <ColorPicker
                  width={250}
                  height={150}
                  color={color}
                  onChange={(e) => handleChange(e)}
                  hideHSV
                  hideRGB
                  dark
                />
              </div>
            ) : null}
          </Col>
        </Form.Group>
      </form>
    </>
  );
};

export default ProjectForm;
