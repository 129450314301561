import ClientGroup from 'api/clients';
import ProjectGroup from 'api/projects';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ProjectForm from 'components/projects/ProjectForm';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Alert, Breadcrumb, Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import history from 'utils/history';
import TrackifyToast from 'utils/toast';

const EditTeamGroup: React.FC = () => {
  const { id }: any = useParams();
  const queryClient = useQueryClient();

  let projectGroupAPI = new ProjectGroup();
  let clientAPI = new ClientGroup();
  const [errMsg, setErrMsg] = useState<string>('');
  const methods = useForm<any>();

  const projectQuery = useQuery(
    [`project-${id}-edit`, id],
    () => projectGroupAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const clientQuery = useQuery(
    [`get-all-clients`],
    () => clientAPI.list().then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateProject = useMutation((data: any) =>
    projectGroupAPI.update(id, data)
  );

  const onSubmit = (data: any) => {
    if (data.client_id === '') data.client_id = null;
    updateProject.mutate(data, {
      onSuccess: (res: any) => {
        TrackifyToast.success('Project has been edited');
        queryClient.invalidateQueries('projectList');
        history.push(routes.projects.list);
      },
      onError: (error: any) => {
        const errorMessage = error?.response?.data?.error?.details
          ? error?.response?.data?.error?.details[0]?.message
          : error?.response?.data?.error?.message;

        setErrMsg(errorMessage);
      },
    });
  };

  if (projectQuery.isLoading || clientQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (projectQuery.isError || clientQuery.isError) {
    return <Alert variant="danger">Invalid ID</Alert>;
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.projects.list}>Project</Breadcrumb.Item>
        <Breadcrumb.Item href={`${routes.projects.list}`}>
          Project List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Project</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Edit Project</h2>
      <div className="col-md-8">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
            <ProjectForm
              projectData={projectQuery?.data?.data}
              clientData={clientQuery?.data?.data}
            />
            <Button
              variant="primary"
              type="submit"
              style={{ float: 'right' }}
              disabled={methods.formState.isSubmitting}>
              Save
            </Button>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default EditTeamGroup;
