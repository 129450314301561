import { Chart, registerables } from 'chart.js';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { BiExport } from 'react-icons/bi';
import { getTime } from 'utils';
import { prepareExcelData } from 'utils/summaryExcel';

Chart.register(...registerables);

interface Props {
  totalTime: string;
  barReportData: any;
  barReportLabel: any;
  isLegendDisplay: boolean;
  title?: string;
  filterParams: any;
  reports: any;
}

const BarComponent: React.FunctionComponent<Props> = (props) => {
  const {
    barReportLabel,
    title,
    totalTime,
    isLegendDisplay = false,
    barReportData,
    reports,
    filterParams,
  } = props;

  const labels = barReportLabel;
  const data = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: barReportData.map((res: any) => res.duration),
        backgroundColor: barReportData.map((res: any) => res.color),
        borderColor: barReportData.map((res: any) => res.color),
        borderWidth: 2,
        maxBarThickness: 100,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: isLegendDisplay,
      },
      title: {
        display: true,
        text: title,
      },
      tooltip: {
        callbacks: {
          label: (value: any) => {
            return `${getTime(value.raw)}`;
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
        ticks: {
          beginAtZero: true,
          callback: (value: any) => getTime(value),
        },
      },
    },
  };

  /**
   * Export the data filtering by group type and it's keyword
   */
  const handleExport = () => {
    prepareExcelData(filterParams, reports);
  };

  return (
    <>
      <section className="report-bar-component">
        <div
          className="d-flex justify-content-between p-2 mt-3"
          style={{ background: 'rgb(0, 107, 168)', color: '#fff' }}>
          <p>Total: {totalTime}</p>
          <Button onClick={handleExport} title="Export">
            <BiExport title="Export" />
          </Button>
        </div>
        <Card>
          <Card.Body>
            {barReportData && (
              <div style={{ maxWidth: '100%', height: '500px' }}>
                <Bar data={data} options={options} />
              </div>
            )}
          </Card.Body>
        </Card>
      </section>
    </>
  );
};
export default BarComponent;
