import GroupResource from 'api/groups';
import { Pagination } from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import TeamGroupListItem from 'components/teams/TeamGroupListItem';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useQuery } from 'react-query';
import HideControl from 'services/HideControl';
import history from 'utils/history';

interface FilterParams {
  currentPage: number;
  pageSize: number;
}

const TeamMemberGroupList: React.FC = () => {
  let teamGroupAPI = new GroupResource();
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const queryList = useQuery(
    [
      'teamGroupList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };

      const response = await teamGroupAPI.list(queryParams);

      return response?.data;
    }
  );

  const { data: teamGroupList, isLoading: isTeamGroupListLoading } = queryList;

  return (
    <>
      <div>
        <HideControl
          renderNoAccess={(data: any) => {
            return data ? data : '';
          }}>
          <div className="float-sm-right">
            <Button
              style={{ float: 'right' }}
              className="button primary-button"
              onClick={() => history.push(routes.teams.groups.create)}>
              Add
            </Button>
          </div>
        </HideControl>
        <br />
        <div className="mt-5 team-group-wrapper table-responsive">
          <Table>
            <thead>
              <tr>
                <th>NAME</th>
                <th>ACCESS</th>
                <HideControl
                  renderNoAccess={(data: any) => {
                    return data ? data : '';
                  }}>
                  <th>STATUS</th>
                  <th>ACTIONS</th>
                </HideControl>
              </tr>
            </thead>
            <tbody>
              {!isTeamGroupListLoading &&
                teamGroupList?.data?.map(
                  (teamGroupListData: any, index: number) => (
                    <TeamGroupListItem
                      key={teamGroupListData.id}
                      teamGroupData={teamGroupListData}
                    />
                  )
                )}

              {isTeamGroupListLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={3} />
              )}
            </tbody>
          </Table>
          <Pagination
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            dataList={queryList}
          />
        </div>
      </div>
    </>
  );
};

export default TeamMemberGroupList;
