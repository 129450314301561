import ClientResource from 'api/clients';
import ProjectResource from 'api/projects';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ProjectForm from 'components/projects/ProjectForm';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import history from 'utils/history';
import TrackifyToast from 'utils/toast';

const AddProject: React.FC = () => {
  let clientAPI = new ClientResource();
  let projectAPI = new ProjectResource();
  const [errMsg, setErrMsg] = useState<string>('');
  const [clientData, setClientData] = useState<any>();
  const methods = useForm<any>();

  const clientQuery = useQuery(
    [`get-all-clients`],
    () =>
      clientAPI.list({ limit: 1000, status: 'active' }).then((res) => {
        setClientData(res.data.data);
      }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const addProject = useMutation((data: any) => projectAPI.store(data), {
    onSuccess: (res: any) => {
      TrackifyToast.success('Project created sucessfully');
      history.push(routes.projects.list);
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.error?.details
        ? error?.response?.data?.error?.details[0]?.message
        : error?.response?.data?.error?.message;

      setErrMsg(errorMessage);
    },
  });

  const onSubmit = (data: any) => {
    addProject.mutate(data);
  };

  if (clientQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (clientQuery.isError) {
    history.push(routes.projects.list);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.projects.list}>Project</Breadcrumb.Item>
        <Breadcrumb.Item href={`${routes.projects.list}`}>
          Project List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add Project</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Create Project</h2>
      <div className="col-md-8">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
            <ProjectForm clientData={clientData} />

            <Button
              variant="primary"
              type="submit"
              style={{ float: 'right' }}
              disabled={addProject.isLoading}>
              Add
            </Button>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default AddProject;
