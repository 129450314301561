import routes from 'constants/routes';
import React from 'react';
import { BiEdit, BiShow, BiUserCheck } from 'react-icons/bi';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import HideControl from 'services/HideControl';
import { filterProjectMemberByProject } from 'utils';

interface Props {
  projectData: any;
}
const ProjectListItem: React.FC<Props> = (props) => {
  const { user } = useSelector(
    (state: any) => ({
      user: state?.data?.auth?.user,
    }),
    shallowEqual
  );
  let { projectData } = props;

  projectData = filterProjectMemberByProject(projectData, user.id);

  const isManager =
    projectData.members && projectData?.members.length > 0
      ? projectData.members[0].is_manager
      : false;

  return (
    <>
      <tr key={projectData.id}>
        <td
          style={
            projectData?.status === false
              ? { textDecoration: 'line-through' }
              : { textDecoration: 'none' }
          }>
          {projectData?.name}
        </td>
        <td>{projectData?.client?.name || '-'}</td>
        <td>{projectData?.privacy || '-'}</td>

        <td>
          {projectData?.status
            ? projectData.status.charAt(0).toUpperCase() +
              projectData.status.slice(1)
            : '-'}
        </td>
        <td className="action-buttons">
          <RouterLink
            data-toggle="tooltip"
            data-placement="top"
            title="View"
            to={routes.projects.view.replace(':id', projectData.id.toString())}>
            <BiShow />
          </RouterLink>{' '}
          <HideControl
            renderNoAccess={(data: any) => {
              return data ? data : '';
            }}>
            <RouterLink
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
              to={routes.projects.edit.replace(
                ':id',
                projectData.id.toString()
              )}>
              <BiEdit />
            </RouterLink>
          </HideControl>
          <HideControl
            isManager={isManager}
            renderNoAccess={(data: any) => {
              return data ? data : '';
            }}>
            <RouterLink
              data-toggle="tooltip"
              data-placement="top"
              title="Assign Member"
              to={routes.projects.assign_member.replace(
                ':id',
                projectData.id.toString()
              )}>
              <BiUserCheck />
            </RouterLink>
          </HideControl>
        </td>
      </tr>
    </>
  );
};

export default ProjectListItem;
