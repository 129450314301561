import { setLoggedInUser } from 'actions/data/auth';
import { getAuthUser } from 'api/auth';
import UserResource from 'api/user';
import { CenterSpinner } from 'components/common/CenterSpinner';
import routes from 'constants/routes';
import React, { useEffect, useRef } from 'react';
import { Badge, Breadcrumb, Button, Image, Stack } from 'react-bootstrap';
import { BiUser } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import TrackifyToast from 'utils/toast';
import { validateFileSize } from 'utils/validate';
import ChangePassword from './ChangePassword';

const MyProfile: React.FC = () => {
  const userQuery = useQuery<any>(
    `user-auth`,
    () => getAuthUser().then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  /**
   * Dispatch the current changes to the store.
   */
  const dispatch = useDispatch();

  useEffect(() => {
    userQuery?.data && dispatch(setLoggedInUser(userQuery?.data));
  }, [userQuery, dispatch]);

  /**
   * Referencing visually hidden input to upload button.
   */
  const fileInputRef = useRef<HTMLInputElement>(null);

  const userApi = new UserResource();

  if (userQuery.isLoading) {
    <CenterSpinner />;
  }

  const avatar = {
    verticalAlign: 'middle',
    width: '150px',
    height: '150px',
    borderRadius: '50%',
  };

  const handleProfileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!validateFileSize(file?.size)) {
      TrackifyToast.error('Files size must be less than 1MB');
    } else {
      const formData = new FormData();
      formData.append('image', file);
      await userApi.uploadProfile(formData);
      userQuery.refetch();
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.projects.list}>Profile</Breadcrumb.Item>
        <Breadcrumb.Item>{userQuery?.data?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <h2>{userQuery?.data?.name}</h2>
      <div className="row mt-5">
        <div className="col-xl-2 col-md-5  col-sm-12 mb-2">
          <Stack>
            {userQuery?.data?.image_url &&
              userQuery.data.image_url !== null && (
                <Image
                  src={userQuery?.data?.image_url}
                  style={avatar}
                  className="border mx-auto"
                  alt={userQuery?.data?.name}
                />
              )}
            {!userQuery?.data?.image_url && (
              <BiUser className="profile-image border mx-auto" />
            )}
            <input
              type="file"
              ref={fileInputRef}
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleProfileUpload}
            />
            <Button
              className="mt-2 change-btn-wrap"
              size="sm"
              onClick={() => fileInputRef?.current?.click()}>
              Change
            </Button>
          </Stack>
        </div>
        <div className="col-xl-5 col-md-7 col-sm-12 profile-content-wrapper  mb-2">
          <div className="row">
            <div className="col-md-6 col-sm-6 profile_label">Name</div>
            <div className="col-md-6 col-sm-6">{userQuery?.data?.name}</div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 profile_label">Email</div>
            <div className="col-md-6 col-sm-6">{userQuery?.data?.email}</div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 profile_label">Phone</div>
            <div className="col-md-6 col-sm-6">{userQuery?.data?.phone}</div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 profile_label">Status</div>
            <div className="col-md-6 col-sm-6">
              <Badge
                bg={
                  userQuery?.data?.status === 'active' ? 'success' : 'danger'
                }>
                {userQuery?.data?.status}
              </Badge>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 profile_label">Role</div>
            <div className="col-md-6">
              <Badge bg="info">{userQuery?.data?.role?.name}</Badge>{' '}
              {userQuery?.data?.is_manager && <Badge bg="info">Manager</Badge>}
            </div>
          </div>
        </div>
        <div></div>
        <div></div>
      </div>

      <div className="mt-5 col col-md-6">
        <h2>Change Password</h2>
        <div className="mt-2">
          <ChangePassword />
        </div>
      </div>
    </>
  );
};

export default MyProfile;
