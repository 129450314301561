import ProjectResource from 'api/projects';
import TagResource from 'api/tags';
import TimeSheetResource from 'api/timesheet';
import { ReactComponent as TagIcon } from 'assets/icons/tagIcon.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Form,
  OverlayTrigger,
  Popover,
  Tooltip,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { BiDollar } from 'react-icons/bi';
import { FcOvertime } from 'react-icons/fc';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import {
  formatTrackerTime,
  getCurrentUTCDateTime,
  getTimeDiffInSec,
} from 'utils';
import { IProject } from 'utils/interfaces/projectInterface';
import TrackifyToast from 'utils/toast';
import TagList from './TagList';
import moment from 'moment-timezone';

interface Props {
  addLog: Function;
}
const TimeTrackerForm: React.FC<Props> = (props) => {
  const { addLog } = props;
  const timeSheetAPI = new TimeSheetResource();
  const projectAPI = new ProjectResource();
  const tagApi = new TagResource();
  const methods = useForm<any>();
  const [timer, setTimer] = useState(0);
  let increment: { current: NodeJS.Timeout | null } = useRef(null);
  const queryClient = useQueryClient();
  const [description, setDescription] = useState('');
  const [currentTrackerID, setCurrentTrackerID] = useState<number>(0);
  const [timeFrom, setTimeFrom] = useState<any>('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [isBillable, setIsBillable] = useState<boolean>(true);
  const [isOT, setIsOT] = useState<boolean>(false);
  const [showTag, setShowTag] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [projectId, setProjectId] = useState('');

  const { data: projectList, isLoading: isProjectListLoading } = useQuery<
    any,
    unknown,
    IProject[],
    any
  >(['projectList'], async () => {
    const response = await projectAPI.list({ status: 'active', limit: 1000 });
    return response?.data?.data;
  });

  const { data: tagList, isLoading: istaglistloading } = useQuery(
    ['taglist'],
    async () => {
      const response = await tagApi.list({ status: true });
      return response?.data?.data;
    }
  );
  const popover = (res?: any) => {
    return (
      <Popover id="popover-basic">
        <Popover.Body style={{ width: '350px', height: '175px' }}>
          <TagList
            tagListData={tagList}
            id={currentTrackerID}
            setSelectedTags={setSelectedTags}
            tagData={selectedTags}
            selectedTags={selectedTags}
          />
        </Popover.Body>
      </Popover>
    );
  };
  const inProgressQuery: any = useQuery(
    ['inProgressQuery'],
    async () => {
      const response = await timeSheetAPI.inProgressTimeSheet();
      return response?.data?.data;
    },
    {
      onSuccess: (data) => {
        if (data) {
          setTimeFrom(data.time_from);
          const diffInSec = getTimeDiffInSec(new Date(), data.time_from);
          setTimer(Math.ceil(diffInSec));
          setTrackerTime();
          setDescription(data?.description);
          setIsBillable(data?.is_billable || false);
          setIsOT(data?.is_ot || false);
          setSelectedTags(data?.TimeSheetTimeSheetTag || []);
          setProjectId(data.project_id || '');
        } else {
          setDescription('');
          setSelectedTags([]);
          setProjectId('');
          setIsBillable(true);
          setCurrentTrackerID(0);
          clearTimer();
        }
      },
    }
  );
  const { data: inProgressTimeSheet, isLoading: inProgressTimeSheetLoading } =
    inProgressQuery;
  const onSubmit = async (data: any) => {
    data.description = data.description.trim();
    if (data.description.length < 6) {
      methods.formState.errors.description = true;
      TrackifyToast.error('Description must be atleast 6 character');
      return;
    }

    if (!projectId) {
      TrackifyToast.error('Please select a project');
      return;
    }

    data.is_billable = isBillable;
    data.project_id = projectId;
    data.is_ot = isOT;
    if (timer > 0) {
      data.time_from = timeFrom;
      data.time_to = getCurrentUTCDateTime();
      updateTimeSheet.mutate(data);
      setSelectedTags([]);
      setProjectId('');
      clearTimer();
      setIsOT(false);
    } else {
      let tags = [];
      if (selectedTags.length > 0) {
        tags = selectedTags?.map((tag: any) => tag?.tag_id);
      }
      setTrackerTime();
      data.time_from = getCurrentUTCDateTime();
      setTimeFrom(data.time_from);
      data.tags = tags;
      addTimeSheet.mutate(data);
    }
    setDescription('');
  };
  const addTimeSheet = useMutation((data: any) => timeSheetAPI.store(data), {
    onSuccess: (response: any) => {
      // const timeSheetLocalCachedata = queryClient.getQueriesData('timeSheetList')

      setCurrentTrackerID(response?.data?.data?.id);
      // queryClient.invalidateQueries('timeSheetList');
    },
    onError: (error: any, response: any) => { },
  });
  const updateTimeSheet = useMutation(
    (data: any) => timeSheetAPI.update(currentTrackerID, data),
    {
      onSuccess: (res: any) => {
        addLog(true);

        queryClient.setQueriesData('timeSheetList', (oldData: any) => {

          const oldWeekDuration = moment.duration(oldData?.week_map[oldData.week_map.length - 1]?.total_duration)


          const oldDuration = moment.duration(oldData?.data[0]?.total_duration);

          const newDuration = moment.duration(res?.data?.data?.duration);
          const totalDuration = moment.duration(oldDuration.asMilliseconds() + newDuration.asMilliseconds());
          const newWeeekDuration = moment.duration(oldWeekDuration.asMilliseconds() + newDuration.asMilliseconds());
          const newFormattedWeekDuration = moment.utc(newWeeekDuration.asMilliseconds()).format('HH:mm:ss');
          // Format the total duration back into the desired format "HH:mm:ss"
          const formattedTotalDuration = moment.utc(totalDuration.asMilliseconds()).format('HH:mm:ss');

          // Update the relevant part of the data with the new response
          const newData = {
            ...oldData,
            data: [
              {
                ...oldData.data[0],
                total_duration: formattedTotalDuration,
                time_sheets: [res?.data?.data, ...oldData?.data[0]?.time_sheets] // Update time_sheets with the new data
              },
              ...oldData.data.slice(1) // Keep the rest of the data unchanged
            ],
            week_map: [...oldData?.week_map.slice(0, -1), { ...oldData?.week_map[oldData.week_map.length - 1], total_duration: newFormattedWeekDuration }],
            weeks: [{ ...oldData?.weeks[0], total_duration: newFormattedWeekDuration }, ...oldData?.weeks?.slice(1)]
          };
          return newData;
        });
        TrackifyToast.success('Time Sheet has been added');

        // queryClient.invalidateQueries('timeSheetList');
        queryClient.invalidateQueries('inProgressQuery');
        setCurrentTrackerID(0);
      },
      onError: (error: any, response: any) => { },
    }
  );
  const setTrackerTime = () => {
    clearInterval(increment.current as NodeJS.Timeout);
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };
  const clearTimer = () => {
    methods.reset();
    clearInterval(increment.current as NodeJS.Timeout);
    setTimer(0);
  };
  useEffect(() => {
    if (inProgressTimeSheet?.id) {
      methods.setValue('project_id', inProgressTimeSheet?.project_id);
      methods.setValue('description', inProgressTimeSheet?.description);
      setCurrentTrackerID(inProgressTimeSheet?.id);
      methods.setValue('is_billable', inProgressTimeSheet?.is_billable);
      methods.setValue('is_ot', inProgressTimeSheet?.is_ot);
    }
  }, [inProgressTimeSheet, methods]);
  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) {
          setIsMobile(ismobile);
        }
      },
      false
    );
  }, [isMobile]);

  const handleChange = (value: any, type: string) => {
    let data: any = {};
    if (type === 'description') {
      const description = value.trim();
      setDescription(description);
      data.description = description;
      if (description && description.length < 6) {
        methods.formState.errors.description = true;
        TrackifyToast.error('Description must be atleast 6 character');
        return;
      } else if (description && description.length > 128) {
        methods.formState.errors.description = true;
        TrackifyToast.error('Description must be less than 128 character');
        return;
      } else {
        methods.formState.errors.description = false;
      }
    }

    if (type === 'description') {
      setDescription(value);
    }

    if (type === 'project') {
      setProjectId(value);
      data.project_id = value;
      const projectDetail = projectList?.find(
        (project: any) => project.id === Number(value)
      );
      setIsBillable(projectDetail ? projectDetail?.is_billable : false);
      data.is_billable = projectDetail ? projectDetail?.is_billable : false;
    }
    if (type === 'billable') {
      const isBillable = !!value ? false : true;
      setIsBillable(isBillable);
      data.is_billable = isBillable;
    }
    if (type === 'ot') {
      const isOT = !!value ? false : true;
      setIsOT(isOT);
      data.is_ot = isOT;
    }
    if (currentTrackerID !== 0) {
      updateTimeSheet.mutate(data);
    }
  };

  return (
    <>
      {!inProgressTimeSheetLoading && (
        <Card className="mt-3">
          <Card.Body className="p-3">
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="row time-input--wrapper">
                <div className=" col-xl-4 col-md-12 mt-1 mb-1 timetracker--form">
                  <Form.Control
                    type="text"
                    defaultValue={description}
                    placeholder="What are you working on? (min. 6 characters)"
                    {...methods.register('description', {
                      required: 'Must be 6 character long.',
                    })}
                    onBlur={(e) => handleChange(e.target.value, 'description')}
                    className={
                      methods.formState.errors.description ? 'is-invalid' : ''
                    }
                  />
                  {/* TODO: Enable this error and fix the misalignment */}
                  <Form.Control.Feedback type="invalid">
                    {methods.formState.errors.description &&
                      methods.formState.errors.description.message}
                  </Form.Control.Feedback>
                </div>
                <div className="right-content-wrap col-xl-8 col-md-12">
                  <div
                    className={
                      isMobile
                        ? 'col-md-12 col-sm-12 mt-1 mb-1 time-select-wrap'
                        : 'col-xl-4 col-md-12 time-select-wrap'
                    }>
                    {/* React-Select is useful for implementing searchable select options */}
                    <Controller
                      control={methods.control}
                      name="project_id"
                      defaultValue={projectId}
                      render={({ field }) => (
                        <Select
                          placeholder="Select Project"
                          id="project_id"
                          {...field}
                          options={projectList}
                          onChange={(e: any) => {
                            if (e) {
                              handleChange(e.id && e.id, 'project');
                            } else {
                              setProjectId('');
                            }
                          }}
                          value={
                            projectList?.find(
                              (p) => p.id === Number(projectId)
                            ) || ''
                          }
                          isClearable={true}
                          isSearchable={true}
                          isLoading={false}
                          escapeClearsValue
                          getOptionLabel={(options: any) => options['name']}
                          getOptionValue={(options: any) => options['id']}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: '20px',
                              borderColor: '#C6D2D9',
                            }),
                            input: (baseStyles, state) => ({
                              ...baseStyles,
                              margin: '-5px',
                            }),
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      )}
                    />
                    {methods.formState.errors.project_id &&
                      methods.formState.errors.project_id?.message && (
                        <Form.Control.Feedback type="invalid">
                          {methods.formState.errors.project_id &&
                            methods.formState.errors.project_id?.message}
                        </Form.Control.Feedback>
                      )}
                  </div>
                  {/* tag */}
                  <div
                    className={
                      isMobile ? 'col-sm-2 mt-1 mb-1' : 'col-xl-1 mt-1 mb-1'
                    }>
                    <div className="col-md-12 ">
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="right"
                        overlay={popover()}>
                        <Button
                          style={{
                            boxShadow: 'none',
                            background: 'none',
                            border: 'none',
                          }}
                          onClick={() => {
                            setShowTag(!showTag);
                          }}>
                          <TagIcon />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <Controller
                    control={methods.control}
                    name="is_billable"
                    render={({ field }) => (
                      <h4 className="col-xl-1 col-md-2 text-center mt-1 mb-1">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="tooltip1">Billable</Tooltip>}>
                          <Button
                            className="bg-white border-0"
                            style={
                              isBillable && !!isBillable
                                ? { color: 'rgb(0, 107, 168)' }
                                : { color: 'gray' }
                            }
                            onClick={() => {
                              handleChange(
                                isBillable ? isBillable : false,
                                'billable'
                              );
                            }}>
                            <BiDollar style={{ fontSize: '25px' }} />
                          </Button>
                        </OverlayTrigger>
                      </h4>
                    )}
                  />
                  {methods.formState.errors.is_billable &&
                    methods.formState.errors.is_billable?.message && (
                      <Form.Control.Feedback type="invalid">
                        {methods.formState.errors.is_billable &&
                          methods.formState.errors.is_billable?.message}
                      </Form.Control.Feedback>
                    )}
                  <Controller
                    control={methods.control}
                    name="is_ot"
                    render={({ field }) => (
                      <h4 className="col-xl-1 col-md-1 text-center mt-1 mb-1 ot-parent">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="tooltip1">OT</Tooltip>}>
                          <Button
                            className="btn-light ot-btn"
                            style={
                              isOT && !!isOT
                                ? {
                                  borderColor: 'rgb(0, 107, 168)',
                                  opacity: '1',
                                }
                                : {
                                  borderColor: '#f5f5f5',
                                  opacity: '0.6',
                                }
                            }
                            onClick={() => {
                              handleChange(isOT ? isOT : false, 'ot');
                            }}>
                            <FcOvertime
                              transform="scale(2)"
                              className="ot-icon"
                            />
                          </Button>
                        </OverlayTrigger>
                      </h4>
                    )}
                  />
                  {methods.formState.errors.is_ot &&
                    methods.formState.errors.is_ot?.message && (
                      <Form.Control.Feedback type="invalid">
                        {methods.formState.errors.is_ot &&
                          methods.formState.errors.is_ot?.message}
                      </Form.Control.Feedback>
                    )}
                  <div className="col-xl-3 col-md-4 timer-wrap mt-1 mb-1">
                    {formatTrackerTime(timer)}
                  </div>
                  <div className="col-md-2 co-xl-2 text-right start-timer-button-wrapper">
                    <Button
                      variant={timer ? 'danger' : 'primary'}
                      size="sm"
                      type="submit"
                      disabled={methods.formState.isSubmitting}>
                      {timer ? 'Stop' : 'Start'}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Card.Body>
        </Card>
      )}
    </>
  );
};
export default TimeTrackerForm;
