import routes from 'constants/routes';
import AddClient from 'pages/dashboard/Clients/AddClient';
import EditClient from 'pages/dashboard/Clients/EditClient';
import ListClient from 'pages/dashboard/Clients/ListClient';
import MyProfile from 'pages/dashboard/Profile/MyProfile';
import AddProject from 'pages/dashboard/Projects/AddProject';
import AssignProjectMember from 'pages/dashboard/Projects/AssignProjectMember';
import EditProject from 'pages/dashboard/Projects/EditProject';
import ListProject from 'pages/dashboard/Projects/ListProject';
import ViewProject from 'pages/dashboard/Projects/ViewProject';
import ReportsScreen from 'pages/dashboard/Reports/ReportsScreen';
import { SplashScreen } from 'pages/dashboard/SplashScreen';
import AddTag from 'pages/dashboard/Tags/AddTag';
import EditTag from 'pages/dashboard/Tags/EditTag';
import ListTag from 'pages/dashboard/Tags/ListTag';
import AddTeamGroup from 'pages/dashboard/Teams/AddTeamGroup';
import AddTeamMember from 'pages/dashboard/Teams/AddTeamMember';
import AssignTeamMember from 'pages/dashboard/Teams/AssignTeamMember';
import EditTeamGroup from 'pages/dashboard/Teams/EditTeamGroup';
import EditTeamMember from 'pages/dashboard/Teams/EditTeamMember';
import TeamPage from 'pages/dashboard/Teams/TeamPage';
import ViewTeamMember from 'pages/dashboard/Teams/ViewTeamMember';
import TrackerList from 'pages/dashboard/Tracker/TrackerList';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import RoleBasedRouting from './RoleBasedRouting';

const PrivateRouter: React.FC = () => {
  return (
    <Switch>
      <Route exact path={routes.dashboard} component={SplashScreen} />
      <Route exact path={routes.reports} component={ReportsScreen} />

      <Route exact path={routes.teams.list} component={TeamPage} />

      <RoleBasedRouting
        exact
        path={routes.teams.groups.create}
        component={AddTeamGroup}
      />
      <RoleBasedRouting
        exact
        path={routes.teams.groups.edit}
        component={EditTeamGroup}
      />
      <RoleBasedRouting
        exact
        path={routes.teams.groups.assign_member}
        component={AssignTeamMember}
      />

      <RoleBasedRouting
        exact
        path={routes.teams.members.create}
        component={AddTeamMember}
      />
      <RoleBasedRouting
        exact
        path={routes.teams.members.edit}
        component={EditTeamMember}
      />
      <RoleBasedRouting
        exact
        path={routes.teams.members.view}
        component={ViewTeamMember}
      />

      <Route exact path={routes.projects.list} component={ListProject} />
      <RoleBasedRouting
        exact
        path={routes.projects.edit}
        component={EditProject}
      />
      <Route exact path={routes.projects.view} component={ViewProject} />
      <RoleBasedRouting
        exact
        path={routes.projects.create}
        component={AddProject}
      />
      <RoleBasedRouting exact path={routes.tags.list} component={ListTag} />
      <RoleBasedRouting exact path={routes.tags.edit} component={EditTag} />
      <RoleBasedRouting exact path={routes.tags.create} component={AddTag} />
      <RoleBasedRouting
        exact
        path={routes.projects.assign_member}
        component={AssignProjectMember}
      />

      <RoleBasedRouting
        exact
        path={routes.clients.list}
        component={ListClient}
      />
      <RoleBasedRouting
        exact
        path={routes.clients.edit}
        component={EditClient}
      />
      <RoleBasedRouting
        exact
        path={routes.clients.create}
        component={AddClient}
      />

      <Route exact path={routes.profile.myProfile} component={MyProfile} />
      <Route exact path={routes.tracker.list} component={TrackerList} />
    </Switch>
  );
};

export default PrivateRouter;
